/**
 * Stores used during customer add/edit
 */
import { defineStore } from 'pinia'
import getFormConfigStore from './formConfig'
import { collectionHelpers } from './modelStore'
import api from '@/api/index'
export const useCustomerConfigStore = getFormConfigStore('customer')

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    loading: true,
    error: false,
    collection: [],
    collectionMetaData: {},
    customerId: null,
    item: {},
  }),
  actions: {
    setCurrentCustomerId(customerId) {
      this.customerId = customerId
    },
    async loadCustomers(companyId, options) {
      this.loading = true
      return api.customers
        .getCustomers(companyId, options)
        .then((result) => {
          let collection
          if (options.pageOptions && result.length == 2) {
            this.collectionMetaData = result[0]
            collection = result[1]
          } else {
            collection = result
          }
          this.loading = false
          this.error = ''
          this.collection = collection
          return collection
        })
        .catch(this.handleError)
    },
    async loadCustomer(customerId = null) {
      customerId = customerId || this.customerId
      if (!customerId) {
        throw Error('no Id provided')
      }
      this.loading = true
      return api.customers
        .load(customerId)
        .then((item) => {
          this.loading = false
          this.error = false
          this.item = item
          return item
        })
        .catch(this.handleError)
    },
    async handleError(error) {
      this.loading = false
      this.error = error
      return Promise.reject(error)
    },
    async saveCustomer(data) {
      let customerId = this.customerId || data.id
      if (customerId) {
        return api.customers.update(data, customerId)
      } else {
        return this.createCustomer(data)
      }
    },
    async createCustomer(data) {
      if (data.id) {
        throw Error('Customer already exists (has an id)')
      }
      const configStore = useCustomerConfigStore()
      const companyId = configStore.getOptions('company_id')
      if (!companyId) {
        throw Error('Missing company_id')
      }
      api.customers.setCompanyId(companyId)
      return api.customers.create(data)
    }
  },
  getters: collectionHelpers('customer'),
})
