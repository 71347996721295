import { isProxy, toRaw } from 'vue'
import { defineStore } from 'pinia'
import http from '@/helpers/http'
import { clone } from '../helpers/utils'

const CONST_API_ENDPOINT = '/api/v1/consts/'
const getConstApiUrl = (key) => {
  return CONST_API_ENDPOINT + key
}
/**
 * Store managing form configuration options
 *
 * Allow to load constants from /api/v1/consts/<name>
 *
 * form_config can have sections, actions, options, schemas
 */
export const useConstStore = defineStore({
  id: 'constants',
  state: () => ({
    consts: {},
  }),
  actions: {
    /**
     *
     * @param {String} constName : Nom de constantes à charger
     * @param {Boolean} refresh : force un refresh des constantes
     * @returns a Promise resolving to the loaded form configuration
     */
    async loadConst(constName, refresh = false) {
      console.log(`Loading the ${constName} from the pinia store`)
      if (refresh || !this.consts[constName]) {
        return http.get(getConstApiUrl(constName)).then((options) => {
          this.consts[constName] = options
          return options
        })
      } else {
        return Promise.resolve(this.consts[constName])
      }
    },
  },
})
