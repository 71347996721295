<script setup>
  import InputLabel from './InputLabel.vue'
  import Button from '../Button.vue'
  import FieldErrorMessage from './FieldErrorMessage.vue'
  import { formatDate } from '@/helpers/date'
  import useFileUploadField from './composables/useFileUploadField'

  import { humanFileSize } from '../../helpers/utils'
  /**
   * Single File Upload widget
   *
   * Allow to upload one file and display the
   * current file informations
   */

  const props = defineProps({
    name: { type: String, required: true },
    label: { type: String, default: '' },
    downloadLabel: { type: String, default: 'Choisir un fichier' },
    icon: { type: String, default: 'pen' },
    value: { type: Object, default: {} },
    description: { type: String, default: '' },
    /* Url for the file action (when we click on the filename)*/
    fileUrl: { type: String || null, default: null },
    /* File informations as name, size */
    fileInfo: { type: Object || null, default: null },
    required: { type: Boolean || null, default: false },
    maxSize: { type: Number },
  })

  const emits = defineEmits(['changeValue', 'blurValue'])


  const {
    nameRef,
    tagId,
    currentFileInfo,
    fileInputRef,
    value,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    onPickFile,
    onFilePicked,
    onDeleteClicked,
  } = useFileUploadField(
    props,
    emits,
  )
</script>
<template>
  <div
    class="form-group File"
    :class="{ 'has-error': meta.touched && !!errorMessage }"
  >
    <div>
      <InputLabel :tagId="tagId" :required="required" :label="label" />
    </div>
    <div v-if="currentFileInfo || fileInfo">
      <span class="data">
        <a v-if="currentFileInfo" @click="onPickFile">
          {{ currentFileInfo.name }}
        </a>
        <a v-else-if="fileUrl && fileInfo" :href="fileUrl" target="_blank">
          {{ fileInfo.name }}
        </a>
        <Button
          icon="pen"
          class="icon unstyled"
          @click="onPickFile"
          type="button"
          :label="downloadLabel"
          :showLabel="false"
        >
        </Button>
        <br />
        <small class="help-block">
          {{ humanFileSize(currentFileInfo.size) }}
          <template v-if="fileInfo">
            <br />
            Déposé le {{ formatDate(fileInfo.created_at) }}
            <br />
            Dernière modification le {{ formatDate(fileInfo.updated_at) }}
          </template>
        </small>
      </span>
    </div>
    <Button
      v-else
      icon="pen"
      class="btn btn-info"
      @click="onPickFile"
      type="button"
      :label="downloadLabel"
      :showLabel="true"
    >
    </Button>
    <input
      type="file"
      :name="nameRef"
      style="display: none"
      ref="fileInputRef"
      accept="image/*,.pdf,.PDF,.ods,.xlsx,.xls"
      @change="onFilePicked"
    />
    <small class='help-block' v-if="description || maxSize">
      <span v-if="description" v-html="description"></span>
      <br v-if="description && maxSize"/>
      Taille maximale : {{ humanFileSize(maxSize) }}
    </small>
    <FieldErrorMessage
      v-if="meta.touched && !!errorMessage"
      :message="errorMessage"
    />
  </div>
</template>
