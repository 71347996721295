<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    /**
     * Icon name
     * From those available on https://endi.sophieweb.com/html/icones.html
     */
    name: { type: String, required: true },
    /**
     * Optional CSS Class ot add on the <svg> tag
     */
    css: { type: String, default: '' },
  })

  const url = computed(() => '/static/icons/endi.svg#' + props.name)
</script>

<template>
  <svg :class="css">
    <use :href="url"></use>
  </svg>
</template>
