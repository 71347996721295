<script setup>
  /**
   * Button
   *
   * @example <Button label="Cliquer pour voir" @click="clickCallback" icon="plus" showLabel=false css='morecss' title="AlternativeTitle" />
   */
  import Icon from './Icon.vue'

  const props = defineProps({
    label: { type: String },
    icon: String,
    showLabel: { type: Boolean, default: false },
    css: { type: String, default: '' },
    title: { type: String, default: '' },
    buttonType: { type: String, default: 'button' },
  })
  const emits = defineEmits(['click'])

  let cssClass = 'btn icon'
  if (!props.showLabel) {
    cssClass += ' only'
  }
  cssClass += ' ' + props.css

  const titleToUse = props.title ? props.title : props.label
</script>
<template>
  <button
    :class="cssClass"
    @click="emits('click')"
    :title="titleToUse"
    :aria-label="titleToUse"
    :type="buttonType"
  >
    <Icon v-if="icon" :name="icon" />
    <template v-if="showLabel">
      {{ label }}
    </template>
    <template v-else>
       <span class="screen-reader-text">{{  title }}</span>
    </template>
  </button>
</template>
