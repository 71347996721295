<script setup>
  const emit = defineEmits(['close', 'submitForm'])

  // const onClose = (event) => emit('close');
  const onSubmit = (event) => emit('submitForm', event)
</script>
<template>
  <form @submit="onSubmit">
    <slot name="fields"></slot>
    <div class="btn-group">
      <slot name="buttons"></slot>
    </div>
  </form>
</template>
