<script setup>
  import IconRequired from '../IconRequired.vue'
  import Icon from '../Icon.vue'

  const props = defineProps({
    required: { type: Boolean, default: false },
    tagId: { type: String },
    label: { type: String },
  })
</script>
<template>
  <label
    :for="tagId"
    :class="required && 'required'"
    :title="required && 'Champ obligatoire'"
  >
    <Icon name="exclamation-triangle" class="error" />
    {{ label }}
    <IconRequired v-if="required"></IconRequired>
  </label>
</template>
