<script setup>
  import { ref } from 'vue'
  import { storeToRefs } from 'pinia'

  import { useCustomerStore, useCustomerConfigStore } from '@/stores/customer'
  import { useConstStore } from '@/stores/const'
  import FormFlatLayout from '@/layouts/FormFlatLayout.vue'

  import CustomerForm from './CustomerForm.vue'

  // props attendu par le composant
  const props = defineProps({
    edit: { type: Boolean, default: false },
    customerId: { type: Number || null, default: null },
    url: { type: String, required: true },
    formConfigUrl: { type: String, required: true },
    layout: { type: Object, default: FormFlatLayout },
  })
  const emit = defineEmits(['saved', 'cancel'])

  // Préparation du chargement des données
  const loading = ref(true)
  const formConfigStore = useCustomerConfigStore()
  formConfigStore.setUrl(props.formConfigUrl)
  const constStore = useConstStore()
  const isEdit = !!props.customerId
  const customerStore = useCustomerStore()

  async function preload() {
    const promises = [
      constStore.loadConst('countries'),
      formConfigStore.loadConfig(),
    ]

    if (isEdit) {
      customerStore.setCurrentCustomerId(props.customerId)
      promises.push(customerStore.loadCustomer())
    }
    Promise.all(promises).then(() => (loading.value = false))
  }

  // Initialisation des éléments utilisés pour initialiser le formulaire
  const { item: customer } = storeToRefs(customerStore)

  function onSaved(item) {
    console.log('Customer saved')
    emit('saved', item)
  }
  function onCancel() {
    console.log('Cancel Customer add/edit')
    emit('cancel')
  }
  // Chargement (await toujours en fin de setup)
  await preload()
  // Chargement terminé
</script>
<template>
  <div v-if="loading">Chargement des informations</div>
  <CustomerForm
    v-else
    :customer="customer"
    @saved="onSaved"
    @cancel="onCancel"
    :layout="layout"
  />
</template>
