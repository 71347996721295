<script setup>
  import { inject } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useField } from 'vee-validate'

  import Input from '@/components/forms/Input.vue'
  import Select2 from '@/components/forms/Select2.vue'
  import { formContextInjectionKey } from '@/helpers/form'
  import CityInput from '../address/CityInput.vue'
  import { useConstStore } from '@/stores/const'

  const { getFormFieldData } = inject(formContextInjectionKey)

  const constStore = useConstStore()
  const departments = await constStore.loadConst('departments')
  const street_types = await constStore.loadConst('street_types')
  const street_number_complements = await constStore.loadConst(
    'street_number_complements'
  )
  const { consts } = storeToRefs(constStore)

  const { value: countryCode } = useField('urssaf_data.birthplace_country_code')
</script>
<template>
  <h4 class="content_vertical_padding separate_top">
    Complément d'information sur l’adresse postale
  </h4>
  <div>
    <Input type="hidden" v-bind="getFormFieldData('urssaf_data.id')" />
    <Input type="hidden" v-bind="getFormFieldData('urssaf_data.customer_id')" />
    <div class="row form-row">
      <div class="col-md-3">
        <Input v-bind="getFormFieldData('urssaf_data.street_number')" />
      </div>
      <div class="col-md-3">
        <Select2
          :options="street_types"
          v-bind="getFormFieldData('urssaf_data.street_type')"
          idKey="code"
          labelKey="name"
        />
      </div>
      <div class="col-md-6">
        <Input v-bind="getFormFieldData('urssaf_data.street_name')" />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-3">
        <Select2
          :options="street_number_complements"
          v-bind="getFormFieldData('urssaf_data.street_number_complement')"
          idKey="code"
          labelKey="name"
        />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-8">
        <Input v-bind="getFormFieldData('urssaf_data.lieu_dit')" />
      </div>
      <div class="col-md-4 form-group String">
        <Input v-bind="getFormFieldData('city_code')" />
      </div>
    </div>
  </div>
  <h4 class="content_vertical_padding separate_top">
    Date et lieu de naissance du client
  </h4>
  <div>
    <div class="row form-row">
      <div class="col-md-8">
        <Input v-bind="getFormFieldData('urssaf_data.birth_name')" />
      </div>
      <div class="col-md-4">
        <Input v-bind="getFormFieldData('urssaf_data.birthdate')" />
      </div>
    </div>
  </div>
  <div>
    <div class="row form-row">
      <div class="col-md-6">
        <Select2
          :options="consts.countries"
          v-bind="getFormFieldData('urssaf_data.birthplace_country_code')"
          idKey="code_insee"
          labelKey="name"
        />
      </div>

      <div class="col-md-6">
        <Select2
          v-if="countryCode == 99100 || false"
          :options="departments"
          v-bind="
            Object.assign(
              getFormFieldData('urssaf_data.birthplace_department_code'),
              { required: true }
            )
          "
          idKey="code_insee"
          labelKey="name"
        />
      </div>
    </div>
    <CityInput :completion="countryCode == 99100" />
  </div>
  <h4 class="content_vertical_padding separate_top">Coordonnées bancaires</h4>
  <div>
    <div class="row form-row">
      <div class="col-md-12">
        <Input v-bind="getFormFieldData('urssaf_data.bank_account_iban')" />
      </div>
    </div>
  </div>
  <div>
    <div class="row form-row">
      <div class="col-md-6">
        <Input v-bind="getFormFieldData('urssaf_data.bank_account_bic')" />
      </div>
      <div class="col-md-6">
        <Input v-bind="getFormFieldData('urssaf_data.bank_account_owner')" />
      </div>
    </div>
  </div>
</template>
