import { ILoadOptions } from './interfaces'
import { ModelApiService, loadCollection } from './modelApi'
import http from '@/helpers/http'

class ProjectApiService extends ModelApiService {
  constructor() {
    super('/api/v1/projects')
  }
  getProjects(companyId: number, options?: ILoadOptions): Promise<any> {
    const url = this.setCompanyId(companyId)
    return loadCollection(url, options)
  }
  setCompanyId(companyId: string): void {
    const url = `/api/v1/companies/${companyId}/projects`
    this.setCollectionUrl(url)
    return url
  }
  /**
   * Load the Business/Task tree for the given project
   */
  getTree(projectId: number, businessId: number, taskId: number): Promise<any> {
    const params = {}
    if (businessId) params.business_id = businessId
    if (taskId) params.task_id = taskId
    const urlParams = new URLSearchParams(params)
    const url = `/api/v1/projects/${projectId}/tree?${urlParams}`

    return http.get(url)
  }
}
export default ProjectApiService
