import { ILoadOptions } from './interfaces'
import { ModelApiService, loadCollection } from './modelApi'
import http from '@/helpers/http'

class NotificationApiService extends ModelApiService {
  constructor() {
    super('/api/v1/notifications')
  }
  async loadCollection(options?: ILoadOptions) {
    return loadCollection(this.collectionUrl, options)
  }
  async markRead(notification) {
    const url = this.getItemUrl(notification.id) + '/mark_read'
    return http.put(url)
  }
  async postPone(notification) {
    const url = this.getItemUrl(notification.id) + '/postpone'
    return http.put(url)
  }
}
export default NotificationApiService
