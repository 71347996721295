import { ModelApiService } from './modelApi'

class TaskApiService extends ModelApiService {
  constructor() {
    super('/api/v1/tasks')
  }
  setAddUrl(url: string) {
    this.setCollectionUrl(url)
  }
}
export default TaskApiService
