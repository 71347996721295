import FileApiService from '@/api/FileApiService'

/** Handle files that may not be bound to any parent
 *
 * FileApiService describes files that are required to have a parent (of type Node).
 * OrphanFileApiService enables to handle files that do not have yet a parent
 * This may happen for some data structures and when attached files are uploaded *before* their parent.
 */
class OrphanFileApiService extends FileApiService {
  getNewFileUrl(data) {
      return this.collectionUrl
  }
}

export default OrphanFileApiService
