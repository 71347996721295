export const getOneMonthAgo = function(){
    var today = new Date();
    var year = today.getUTCFullYear();
    var month = today.getUTCMonth() - 1;
    var day = today.getUTCDate();
    return new Date(year, month, day);
}

export const parseDate = function(isoDate){
    /*
     * Returns a js Date object from an iso formatted string
     */
    var splitted = isoDate.split('-');
    var year = parseInt(splitted[0], 10);
    var month = parseInt(splitted[1], 10) - 1;
    var day = parseInt(splitted[2], 10);
    return new Date(year, month, day);
}

export const getDateFromIso = parseDate;

export const formatPaymentDate = function(isoDate){
    /*
     *  format a date from iso to display format
     */
    const dateRegexp = /^(\d{1,2})\/(\d{1,2})\/(\d{4})/;

    if (dateRegexp.test(isoDate)) {
        return isoDate;
    }

    if (isoDate){  // catches null/undefined/""
        var dateObject = parseDate(isoDate);
        return dateToLocaleFormat(dateObject);
    } else {
        return "";
    }
}
export const formatDate = formatPaymentDate;

export const dateToIso = function(dateObject){
    var year = dateObject.getFullYear();
    var month = dateObject.getMonth()+1;
    var dt = dateObject.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    return year + "-" + month + "-" + dt;
}

export const dateToLocaleFormat = function(dateObject){
    var year = dateObject.getFullYear();
    var month = dateObject.getMonth()+1;
    var dt = dateObject.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    return dt + '/' + month + '/' + year;
}
