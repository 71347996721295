import { ILoadOptions } from './interfaces'
import { ModelApiService, loadCollection } from './modelApi'
/**
 * Api Pour l'édition des customers
 */
export default class CustomerApiService extends ModelApiService {
  constructor() {
    super('/api/v1/customers')
  }
  getCustomers(companyId: number, options?: ILoadOptions) {
    const url = this.setCompanyId(companyId)
    return loadCollection(url, options)
  }
  setCompanyId(companyId: number): string {
    const url = `/api/v1/companies/${companyId}/customers`
    this.setCollectionUrl(url)
    return url
  }
}
