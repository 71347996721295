<script setup>
  import { ref, inject } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useFormValues } from 'vee-validate'

  import { addressFeatureToCustomerData } from '@/helpers/apiGouvAdresse'
  import { formContextInjectionKey } from '@/helpers/form'
  import { useCustomerConfigStore } from '@/stores/customer'
  import { useConstStore } from '@/stores/const'
  import Select2 from '@/components/forms/Select2.vue'
  import Input from '@/components/forms/Input.vue'
  import Select from '@/components/forms/Select.vue'

  import AddressInput from './address/AddressInput.vue'
  import ZipCodeCityInput from './address/ZipCodeCityInput.vue'

  const props = defineProps({
    customer: { type: Object },
    internal: { type: Boolean },
  })
  // Form schema informations
  const { getFormFieldData, setFieldValue } = inject(formContextInjectionKey)
  const values = useFormValues()
  // Form configuration
  const formConfigStore = useCustomerConfigStore()
  // Options utilisées plus bas
  const civiliteOptions = formConfigStore.getOptions('civilite_options')
  const { consts } = storeToRefs(useConstStore())

  // Références
  // Si on change de pays on ne veut pas de complétion ce qui est différent de l'option globale
  const addressCompletion = formConfigStore.getOptions('address_completion')
  const completeAddress = ref(addressCompletion)

  // Event sur les champs du formulaire
  const onCountryChange = (countryOption) => {
    const countryData = countryOption.data
    if (countryData) {
      const value = countryData.name
      console.log('Setting country_code %s', countryData.code_insee)

      setFieldValue('country_code', countryData.code_insee)

      if (addressCompletion) {
        completeAddress.value = value === 'FRANCE'
      }
    }
  }
  const onSelectAddress = async (addressFeature) => {
    const data = await addressFeatureToCustomerData(addressFeature)
    for (const [key, value] of Object.entries(data)) {
      if (key == 'urssaf_data') {
        continue
      }
      setFieldValue(key, value)
    }
  }
</script>
<template>
  <fieldset class="deformFormFieldset">
    <legend v-if="!customer.id">Personne morale</legend>
    <legend v-else>
      {{ customer.label }}
      <small v-if="customer.type == 'internal'"> ( Enseigne interne )</small>
      <small v-else> ( Personne morale )</small>
    </legend>
    <div class="row form-row">
      <div class="col-md-12">
        <Input v-bind="getFormFieldData('company_name')" />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-6" v-if="customer.type != 'internal'">
        <Input v-bind="getFormFieldData('registration')" />
      </div>
      <div class="col-md-4">
        <Input v-bind="getFormFieldData('code')" />
      </div>
    </div>

    <h4 class="content_vertical_padding separate_top">Adresse</h4>
    <div class="row form-row">
      <div class="col-md-6">
        <Select2
          v-bind="getFormFieldData('country')"
          idKey="name"
          labelKey="name"
          :options="consts.countries"
          :modelValue="values.country"
          @select="(value) => onCountryChange(value)"
        />

        <Input name="country_code" type="hidden" />
      </div>
      <div class="col-md-6" v-if="customer.type != 'internal'">
        <Input v-bind="getFormFieldData('tva_intracomm')" />
      </div>
    </div>
    <AddressInput
      :completion="completeAddress"
      v-on:select:address="onSelectAddress"
    />
    <ZipCodeCityInput :completion="completeAddress" />
    <h4 class="content_vertical_padding separate_top">Contact principal</h4>
    <div class="row form-row">
      <div class="col-md-4">
        <Select
          v-bind="getFormFieldData('civilite')"
          :addDefault="false"
          :options="civiliteOptions"
        />
      </div>
    </div>

    <div class="row form-row">
      <div
        class="col-md-4"
        v-for="fieldName of ['lastname', 'firstname', 'function']"
      >
        <Input v-bind="getFormFieldData(fieldName)" />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-12">
        <Input v-bind="getFormFieldData('email')" />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-6">
        <Input v-bind="getFormFieldData('mobile')" />
      </div>
      <div class="col-md-6">
        <Input v-bind="getFormFieldData('phone')" />
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-6">
        <Input v-bind="getFormFieldData('fax')" />
      </div>
    </div>
    <template
      v-if="
        formConfigStore.getOptions('is_admin') &&
        formConfigStore.getOptions('display_accounting_config')
      "
    >
      <h4 class="content_vertical_padding separate_top">
        Informations comptables
      </h4>
      <div class="row form-row">
        <div class="col-md-6">
          <Input v-bind="getFormFieldData('compte_cg')" />
        </div>
        <div class="col-md-6">
          <Input v-bind="getFormFieldData('compte_tiers')" />
        </div>
      </div>
    </template>
  </fieldset>
</template>
