<script setup>
  import Icon from '@/components/Icon.vue'
  import { uniqueId } from '../helpers/utils'
  const emit = defineEmits(['close', 'submitForm'])
  const uid = uniqueId()
  const onClose = () => emit('close')
  const onSubmit = (event) => emit('submitForm', event)
</script>
<template>
  <section class="modal_view size_middle">
    <div
      role="dialog"
      :id="uid"
      aria-modal="true"
      :aria-labelledby="'add_' + uid + '_title'"
      class="appear"
    >
      <div class="modal_layout">
        <header>
          <button
            type="button"
            class="icon only unstyled close"
            title="Masquer cette fenêtre"
            aria-label="Masquer cette fenêtre"
            @click="() => onClose()"
          >
            <Icon name="times"></Icon>
          </button>
          <h2 :id="'add_' + uid + '_title'">
            <slot name="title"></slot>
          </h2>
        </header>
        <form @submit="onSubmit" class="modal_content_layout layout">
          <div class="modal_content">
            <slot name="fields"></slot>
          </div>
          <footer>
            <slot name="buttons"></slot>
          </footer>
        </form>
      </div>
    </div>
  </section>
</template>
