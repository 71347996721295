<script setup>
  import { toRef, watch, watchEffect } from 'vue'
  import IconRequired from '../IconRequired.vue'
  import { uniqueId } from '@/helpers/utils'
  import { useField } from 'vee-validate'
  import FieldErrorMessage from './FieldErrorMessage.vue'
  import Icon from '../Icon.vue'

  const props = defineProps({
    name: { type: String, required: true },
    label: { type: String, default: '' },
    value: { type: String || Number || Date, default: '' },
    multiple: { type: Boolean, default: false },
    options: { type: Array, required: true },
    ariaLabel: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    description: { type: String, default: '' },
    onBlur: { type: Function },
    required: { type: Boolean, default: false },
    div_css_class: { type: String, default: '' },
    css_class: { type: String, default: '' },
    editable: { type: Boolean, default: true },
    addDefault: { type: Boolean, default: false },
    defaultOption: {
      type: Object,
      default: () => ({ id: '', label: 'Sélectionner' }),
    },
    idKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
  })
  const emits = defineEmits(['select'])
  const tagId = uniqueId(props.name)

  const nameRef = toRef(props, 'name')
  // On récupère le schéma du formulaire parent
  const { value, errorMessage, handleBlur, handleChange, meta } =
    useField(nameRef)

  if (props.value) {
    value.value = props.value
  }
   watch(
    () => props.value,
    () => value.value = props.value,
  )
</script>
<template>
  <div
    class="form-group String"
    :class="[{ 'has-error': meta.touched && !!errorMessage }, div_css_class]"
  >
    <label
      :for="tagId"
      :class="required && 'required'"
      :title="required && 'Champ obligatoire'"
    >
      <Icon name="exclamation-triangle" class="error" />
      {{ label }}
      <IconRequired v-if="required"></IconRequired>
    </label>
    <select
      :id="tagId"
      class="form-control"
      :class="css_class"
      :name="name"
      v-model="value"
      :placeholder="placeholder"
      :disabled="!editable"
      @change="
        (event) => {
          emits('select', value)
          handleChange(event)
        }
      "
    >
      <option
        v-if="addDefault"
        :disabled="required"
        :value="defaultOption[idKey]"
      >
        {{ defaultOption[labelKey] }}
      </option>
      <option
        v-for="option in options"
        :key="option[idKey]"
        :value="option[idKey]"
      >
        {{ option[labelKey] }}
      </option>
    </select>
    <span v-if="ariaLabel" class="screen-reader-text">{{ ariaLabel }}</span>

    <div class="help-block" v-if="description" v-html="description" />
    <FieldErrorMessage
      v-if="meta.touched && !!errorMessage"
      :message="errorMessage"
    />
  </div>
</template>
