<script setup>
const props = defineProps({
    debug: { type: Object }
})
</script>
<template>
    <div style="max-height: 30vh; max-width: 100%; overflow: auto;" tabindex="0">
        Debug data shown because you're using development js files
        <pre v-text="JSON.stringify(debug, null, 2)" />
    </div>
</template>
