<script setup>
  import { ref, provide, computed } from 'vue'
  import { useForm, useField } from 'vee-validate'

  import { useCustomerConfigStore, useCustomerStore } from '@/stores/customer'
  import {
    formContextInjectionKey,
    getDefaults,
    buildYupSchema,
    getFieldData,
    getSubmitErrorCallback,
  } from '@/helpers/form'
  import Input from '@/components/forms/Input.vue'
  import DebugContent from '@/components/DebugContent.vue'

  import CustomerIndividualForm from './CustomerIndividualForm.vue'
  import CustomerCompanyForm from './CustomerCompanyForm.vue'
  import CustomerInternalForm from './CustomerInternalForm.vue'
  import { getSubmitModelCallback } from '@/helpers/form'

  const props = defineProps({
    customer: { type: Object },
    layout: { type: Object },
  })
  const emit = defineEmits(['saved', 'cancel', 'error'])

  // DEBUG est définie globalement par webpack
  const debug = DEBUG
  const configStore = useCustomerConfigStore()
  const customerStore = useCustomerStore()

  let currentSchemaType, mainType

  if (props.customer.id) {
    currentSchemaType = props.customer.type
    // if (currentSchemaType == 'internal') {
    //   currentSchemaType = 'company'
    // }
    mainType = currentSchemaType

    if (currentSchemaType == 'individual' && props.customer.urssaf_data) {
      currentSchemaType = 'urssaf_data'
    }
  } else {
    mainType = currentSchemaType = configStore.getOptions('default_type')
  }

  // Le type au sens du modèle
  const currentType = ref(mainType)
  // Le type de schéma pour éditer ce modèle
  const schemaType = ref(currentSchemaType)

  const types = configStore.getOptions('types')
  const edit = configStore.getOptions('edit')

  const formSchema = computed(() => {
    console.log('Building form schema %s', schemaType.value)
    const jsonSchema = configStore.getSchema(schemaType.value)
    console.log('Returning form schema')
    console.log(jsonSchema)
    return buildYupSchema(jsonSchema)
  })

  const initialValues = computed(() => {
    const result = getDefaults(formSchema.value)
    result['type'] = currentType.value
    Object.assign(result, props.customer)
    console.log('Returning initialValues')
    console.log(result)
    return result
  })

  // Formulaire vee-validate (se met à jour automatiquement en fonction du schéma)
  const { values, handleSubmit, setFieldValue, isSubmitting, setFieldTouched } =
    useForm({
      validationSchema: formSchema,
      initialValues: initialValues,
    })

  const onSubmitSuccess = getSubmitModelCallback(
    emit,
    customerStore.saveCustomer
  )
  const onSubmitError = getSubmitErrorCallback(emit)
  const onSubmit = handleSubmit(onSubmitSuccess, onSubmitError)
  const onCancel = () => emit('cancel')

  // Utilitaire pour le rendu des champs : renvoie les attributs associés à un champ du formulaire
  const getData = computed(
    () => (fieldName) => getFieldData(formSchema.value, fieldName)
  )

  // Met des informations du formulaire courant à disposition des composants enfants
  provide(formContextInjectionKey, {
    getFormFieldData: getData,
    formSchema: formSchema,
    setFieldValue: (key, value) => {
      setFieldValue(key, value)
      setFieldTouched(key, true)
    },
    schemaType: schemaType,
    onChangeSchemaType: (value) => (schemaType.value = value),
  })

  const Layout = props.layout
  const { value: typeValue, handleChange: handleTypeChange } = useField('type')
  const onTypeChange = (event) => {
    handleTypeChange(event)
    const value = event.target.value
    schemaType.value = value
    currentType.value = value
  }
</script>
<template>
  <Layout @submitForm="onSubmit" @close="onCancel">
    <template v-slot:title>
      <template v-if="customer.id">Modifier un client</template>
      <template v-else>Ajouter un client</template>
    </template>

    <template v-slot:fields>
      <div v-if="!customer.id">
        <h3>Type de client</h3>
        <div class="radio separate_bottom">
          <div class="radio-inline" v-for="typeOption in types">
            <label>
              <input
                type="radio"
                name="type"
                :value="typeOption.value"
                :checked="typeValue == typeOption.value"
                @input="onTypeChange"
              />
              <span>{{ typeOption.label }}</span>
            </label>
          </div>
        </div>
      </div>
      <input v-else type="hidden" name="type" :value="currentType" />
      <CustomerIndividualForm
        v-if="currentType == 'individual'"
        :schemaType="schemaType"
        :customer="customer"
      />
      <CustomerCompanyForm
        v-if="currentType == 'company'"
        :customer="customer"
        :internal="False"
      />
      <CustomerInternalForm
        v-if="currentType == 'internal' && !edit"
        :customer="customer"
      />
      <CustomerCompanyForm
        v-if="currentType == 'internal' && edit"
        :customer="customer"
        :internal="True"
      />
    </template>
    <template v-slot:buttons>
      <br /><br /><br />
      <button
        id="deformsubmit"
        name="submit"
        type="submit"
        class="btn btn-primary"
        value="submit"
        :disabled="isSubmitting"
      >
        Enregistrer
      </button>
      <button
        id="deformcancel"
        name="cancel"
        type="button"
        class="btn btn-default"
        @click="onCancel"
      >
        Annuler
      </button>
      <DebugContent v-if="debug" :debug="values" />
    </template>
  </Layout>
</template>
