import { isProxy, toRaw } from 'vue'

export function collectionHelpers(name) {
  /**
   * Suppose you have a collection in your store state
   */
  return {
    getByid(state) {
      return (id) => {
        id = parseInt(id)
        let result = state.collection.find((item) => item.id == id)
        if (isProxy(result)) {
          result = toRaw(result)
        }
        return result
      }
    },
    filterIn(state) {
      return (ids) => {
        return state.collection.filter((item) => ids.indexOf(item.id) != -1)
      }
    },
    getCollection(state) {
      return (filter) => state.collection.filter(filter)
    },
  }
}
