<script setup>
  import { toRef, watch } from 'vue'
  import { uniqueId } from '@/helpers/utils'
  import { useField } from 'vee-validate'
  import FieldErrorMessage from './FieldErrorMessage.vue'
  import InputLabel from './InputLabel.vue'

  const props = defineProps({
    name: { type: String, required: true },
    label: { type: String, default: '' },
    value: { type: [String, Number, Date], default: '' },
    ariaLabel: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    description: { type: String, default: '' },
    required: { type: Boolean, default: false },
    css_class: { type: String, default: '' },
    type: { type: String, default: 'text' },
    editable: { type: Boolean, default: true },
    dataList: { type: Array, default: () => [] },
  })
  const emits = defineEmits(['changeValue', 'blurValue'])
  const tagId = uniqueId(props.name)

  const nameRef = toRef(props, 'name')
  // On récupère le schéma du formulaire parent
  const { value, errorMessage, handleBlur, handleChange, meta } =
    useField(nameRef)

  if (props.value) {
    value.value = props.value
  }
  watch(
    () => props.value,
    () => value.value = props.value,
  )

  let datalist_id
  if (props.dataList) {
    datalist_id = uniqueId(props.name + '-datalist')
  }
  function onFieldChange(event) {
    handleChange(event)
    emits('changeValue', event.target.value)
  }
  function onFieldBlur(event) {
    handleBlur(event)
    emits('blurValue', event.target.value)
  }
</script>
<template>
  <div
    v-if="type != 'hidden'"
    class="form-group String"
    :class="{ 'has-error': meta.touched && !!errorMessage }"
  >
    <InputLabel :tagId="tagId" :required="required" :label="label" />
    <input
      :id="tagId"
      class="form-control"
      :class="css_class"
      :type="type"
      :name="name"
      v-model="value"
      :aria-label="ariaLabel"
      :placeholder="placeholder"
      :disabled="!editable"
      @blur="onFieldBlur"
      @input="onFieldChange"
      :list="datalist_id"
      checked
    />
    <datalist v-if="dataList" id="datalist_id">
      <option v-for="entry in dataList" value="entry"></option>
    </datalist>
    <div class="help-block" v-if="description" v-html="description" />
    <FieldErrorMessage
      v-if="meta.touched && !!errorMessage"
      :message="errorMessage"
    />
  </div>
  <input v-else type="hidden" :name="name" v-model="value" />
</template>
