<script setup>
  /** Wrapped version of <Icon>
   *
   * Wrapped in a <span class=icon>
   * Also provides extra-features as tooltip and alt-text for a11y tech
   */
  import Icon from '@/components/Icon.vue'

  const props = defineProps({
    /**
     * Icon name
     * From those available on https://endi.sophieweb.com/html/icones.html
     */
    name: { type: String, required: true },
    /**
     * alt text, same logic as for <img> : provide empty string if decorative
     */
    alt: {type: String, required: true},
    /**
     *  CSS class to be set on <span>.
     *  alt="" will set role=presentation (unless `role` prop is set).
     */
    cssClass: {type: String, default: ''},
    /**
     * Optional Aria role override (role= set on <span>)
     */
    role: {
      type: String,
      default: (rawProps) => {
        if (rawProps.role !== undefined) {
          return rawProps.role
        } else {
          if (rawProps.alt === '') {
            return 'presentation'
          } else {
            return undefined
          }
        }
      },
    },
    /**
     * Optional tooltip content
     */
    title: {type: String},
  })
</script>

<template>
  <span :class='["icon", cssClass]' :role='role' :title='title'>
    <Icon :name='name'/>
    <span class="screen-reader-text" v-if='alt'>{{ alt }}</span>
  </span>
</template>
