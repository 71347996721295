<script setup>
  import { inject } from 'vue'

  import Select2 from '@/components/forms/Select2.vue'
  import http from '@/helpers/http'
  import { formContextInjectionKey } from '@/helpers/form'
  import { useCustomerConfigStore } from '@/stores/customer'
  import { storeToRefs } from 'pinia'

  const { getFormFieldData } = inject(formContextInjectionKey)

  const formConfigStore = useCustomerConfigStore()

  const { options } = storeToRefs(formConfigStore)
  let companyOptions = await http.get(
    '/api/v1/companies?fields=id&fields=name'
  )
  companyOptions = companyOptions.filter(
    (item) => item['id'] != options.value.company_id
  )
</script>
<template>
  <fieldset class="deformFormFieldset">
    <div class="row form-row">
      <div class="col-md-12">
        <Select2
          v-bind="getFormFieldData('source_company_id')"
          idKey="id"
          label-key="name"
          :options="companyOptions"
        />
      </div>
    </div>
  </fieldset>
</template>
