import { ILoadOptions } from './interfaces'
import { ModelApiService, loadCollection } from './modelApi'
import http from '@/helpers/http'

/** Api pour la manipulation de files */
class FileApiService extends ModelApiService {
  getNewFileUrl(data) {
    return `/api/v1/nodes/${data.get('parent_id')}/files`
  }
  async create(data: any, url: String): Promise<any> {
    if (! url) {
      url = this.getNewFileUrl(data)
    }
    return super.create(data, url)
  }

  /* Get files attached to a given node. */
  getFiles(nodeId: number, options?: ILoadOptions) {
    const url = `/api/v1/nodes/${nodeId}/files`
    return loadCollection(url, options)
  }
  moveFile(fileId: number, newParentId: number) {
    const url = `/api/v1/files/${fileId}?action=move`
    return http.put(url, { parent_id: newParentId })
  }
}
export default FileApiService
