import { ILoadOptions } from './interfaces'
import { ModelApiService, loadCollection } from './modelApi'
import http from '@/helpers/http'

class BusinessApiService extends ModelApiService {
  constructor() {
    super('/api/v1/businesses')
  }
  loadTemplates(nodeId, options?: ILoadOptions) {
    const url = `/api/v1/businesses/${nodeId}/templates`
    return loadCollection(url, options)
  }
}
export default BusinessApiService
