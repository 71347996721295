import { ref, toRef, defineProps, defineEmits, watch } from 'vue'

import { uniqueId, clone } from '@/helpers/utils'
import { useField } from 'vee-validate'


/**
 * Render-less component for re-usable file upload field logic
 *
 * The component does not include actual uploading on network ; this is up to handling `changeValue` event.
 *
 * File metadata is maintainted through two reactive objects :
 *
 * - fileInfo: the file metadata as passed by parent component
 * - currentFileInfo: metadata of the file selected by user (defaults to a copy of fileInfo)
 *
 * `currentFileInfo`, will starts to differ from `fileInfo` as soon as the user picks a new file and
 * until parent component update `fileInfo` to reflect that new file.
 *
 * @param props
 * @param emits
 */
export default function useFileUploadField(props, emits) {
  const nameRef = toRef(props, 'name')
  const tagId = uniqueId(props.name)
  const currentFileInfo = toRef(props, 'fileInfo')

  const fileInputRef = ref(null)
  // On récupère le schéma du formulaire parent
  const {
    value,
    errorMessage,
    handleBlur,
    handleChange,
    meta
  } = useField(nameRef)


  const onPickFile = (event) => {
    if (event) {
      event.preventDefault()
    }
    fileInputRef.value.click()
  }
  const onFilePicked = (event) => {
    const files = event.target.files
    const currentFile = files[0]
    currentFileInfo.value = currentFile
    const fileReader = new FileReader()
    fileReader.readAsDataURL(files[0])
    emits('changeValue', currentFile)
    handleChange(event)
  }
  const onDeleteClicked = (event) => {
    currentFileInfo.value = null
    // clears the input field
    fileInputRef.value.value = null
    emits('unsetValue')
  }


  return {
    nameRef,
    tagId,
    currentFileInfo,
    fileInputRef,
    value,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    onPickFile,
    onFilePicked,
    onDeleteClicked,
  }
}

