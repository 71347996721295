<script setup>
  import { onClickOutside } from '@vueuse/core'
  import { useField } from 'vee-validate'
  import { ref, inject } from 'vue'
  import { findAddress } from '@/helpers/apiGouvAdresse'
  import { formContextInjectionKey } from '@/helpers/form'
  import Input from '@/components/forms/Input.vue'
  const props = defineProps({
    completion: {
      type: Boolean,
      default: false,
    },
  })

  const emits = defineEmits(['select:address'])
  const { setValue: setAddress } = useField('address')
  const { value: additionalAddress } = useField('additional_address')
  const { getFormFieldData } = inject(formContextInjectionKey)

  let currentQuery
  let addressOptions = ref([])

  const onChangeAddress = (value) => {
    if (props.completion) {
      if (currentQuery) {
        clearTimeout(currentQuery)
      }
      if (value.length > 5) {
        currentQuery = setTimeout(async () => {
          addressOptions.value = await findAddress(value)
        }, 300)
      }
    }
  }

  const onSelectAddress = (index) => {
    console.log('onSelectAddress')
    const selected = addressOptions.value[index]
    if (selected.properties) {
      const selectedAddress = selected.properties
      setAddress(selectedAddress.name)
      addressOptions.value = []
      emits('select:address', selectedAddress)
    }
  }
  const dropdownTarget = ref(null)
  onClickOutside(dropdownTarget, (event) => {
    addressOptions.value = []
  })
  // const onLeaveAddress = (event) => {
  //     console.log("onLeaveAddress");
  //     // addressOptions.value = [];
  //     // addressFieldHandleBlur(event)
  // }
</script>
<template>
  <div>
    <div class="row form-row">
      <div class="col-md-12 form-group String">
        <Input
          v-bind="getFormFieldData('address')"
          @change-value="onChangeAddress"
        />
        <div
          v-if="addressOptions"
          class="form-autocomplete"
          ref="dropdownTarget"
        >
          <ul>
            <li v-for="(option, index) in addressOptions" :key="index">
              <a
                href="javascript:void(0);"
                @click="() => onSelectAddress(index)"
              >
                {{ option.properties.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-12 form-group String">
        <label for="additional_address">Complément d'adresse</label>
        <textarea
          v-model="additionalAddress"
          name="additional_address"
          type="text"
          placeholder="Complément d'adresse"
        />
      </div>
    </div>
  </div>
</template>
