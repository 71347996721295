import http from '@/helpers/http'
import { ModelApiService, loadCollection } from './modelApi'

/** Api pour la manipulation de Indicator */
class IndicatorApiService extends ModelApiService {
  constructor() {
    super('/api/v1/indicators')
  }
  /* Get indicators attached to a node */
  getIndicators(nodeId: number, options?: ILoadOptions) {
    const url = `/api/v1/nodes/${nodeId}/indicators`
    this.setCollectionUrl(url)
    return loadCollection(url, options)
  }
  validateIndicator(indicator) {
    const url = this.getItemUrl(indicator.id) + '?action=validation_status'
    return http.put(url, { validation_status: 'valid' })
  }
  forceIndicator(indicator) {
    const url = this.getItemUrl(indicator.id) + '?action=force'
    return http.put(url)
  }
}
export default IndicatorApiService
