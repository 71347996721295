import CustomerApiService from './CustomerApiService'
import ProjectApiService from './ProjectApiService'
import TaskApiService from './TaskApiService'
import NotificationApiService from './NotificationApiService'
import FileApiService from './FileApiService'
import IndicatorApiService from './IndicatorApiService'
import BusinessApiService from './BusinessApiService'
import {ModelApiService} from "@/api/modelApi";
import OrphanFileApiService from '@/api/OrphanFileApiService'

const api = {
  companies: new ModelApiService("/api/v1/companies"),
  company_logo: new OrphanFileApiService("/api/v1/companies/logo"),
  company_header: new OrphanFileApiService("/api/v1/companies/header"),
  customers: new CustomerApiService(),
  files: new FileApiService("/api/v1/files"),
  indicators: new IndicatorApiService(),
  notifications: new NotificationApiService(),
  projects: new ProjectApiService(),
  tasks: new TaskApiService(),
  businesses: new BusinessApiService(),
}
export default api
