<script setup>
  import { inject } from 'vue'
  import { getAssetPath } from '@/helpers/context'
  import { formContextInjectionKey } from '@/helpers/form'
  import CustomerSapForm from './CustomerSapForm.vue'

  const { setFieldValue, schemaType, onChangeSchemaType } = inject(
    formContextInjectionKey
  )

  const onDelete = () => {
    console.log('')
    setFieldValue('urssaf_data', null)
    onChangeSchemaType('individual')
  }
</script>

<template>
  <fieldset
    id="avance_immediate"
    class="deformFormFieldset separate_top separate_bottom content_vertical_padding"
  >
    <h3>
      Avance immédiate
      <img
        alt="Logo URSSAF"
        :src="getAssetPath('svg/urssaf_logo.svg')"
        width="110"
        height="34"
      />
    </h3>
    <div>
      <div class="row form-row">
        <button
          v-if="schemaType == 'individual'"
          type="button"
          class="btn"
          title="Renseigner les informations permettant d’inscrire ce client à avance immédiate"
          aria-label="Renseigner les informations permettant d’inscrire ce client à avance immédiate"
          @click="() => onChangeSchemaType('urssaf_data')"
        >
          Renseigner les informations
        </button>
        <button
          v-if="schemaType == 'urssaf_data'"
          type="button"
          class="btn btn-danger"
          title="Supprimer les informations permettant d'inscrire ce client à avance immédiate"
          aria-label="Supprimer les informations permettant d'inscrire ce client à avance immédiate"
          @click="onDelete"
        >
          Supprimer les informations saisies
        </button>
      </div>
    </div>
    <CustomerSapForm v-if="schemaType == 'urssaf_data'" />
  </fieldset>
</template>
